var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "text-center mt-4" },
      [
        _c(
          "v-btn",
          {
            staticClass: "mr-3",
            attrs: {
              to: { name: "login", query: { targetUrl: _vm.$route.path } },
              "x-large": "",
            },
          },
          [
            _c("div", [
              _vm._v(" Yes "),
              _c("div", { staticClass: "text-caption text-lowercase" }, [
                _vm._v("take me to login"),
              ]),
            ]),
          ]
        ),
        _c(
          "v-btn",
          { attrs: { to: { name: "signup-options" }, "x-large": "" } },
          [
            _c("div", [
              _vm._v(" No "),
              _c("div", { staticClass: "text-caption text-lowercase" }, [
                _vm._v("set up my account"),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }